import { BehaviorSubject } from 'rxjs';
import { data as en } from './en';
import { data as fr } from './fr';


export type Language = 'en' | 'fr';


const i18nSubject = new BehaviorSubject<Partial<typeof fr>>(fr);
export const i18n$ = i18nSubject.asObservable();
export let i18n: Partial<typeof fr> = undefined;
i18n$.subscribe(val => i18n = val);




const langSubject = new BehaviorSubject<Language | undefined>(undefined);
export const lang$ = langSubject.asObservable();
export const getCurrentLanguage = () => {
    return langSubject.value;
}


const getAppLanguage = () => {
    if (localStorage.getItem('language')) {
        return localStorage.getItem('language') as Language;
    }
    if (navigator?.language?.substring(0, 2) === 'fr') {
        return "fr";
    }
    return "en"
}

export const setLanguage = (language: Language, loadNewLanguage = true) => {

    localStorage.setItem('language', language);
    document.cookie = "language=" + language + ";path=/;max-age=3600"; // expire après 1 heure

    if (loadNewLanguage) {
        langSubject.next(language);

        switch (language) {

            case 'en':
                i18nSubject.next(en);
                break;

            case 'fr':
                i18nSubject.next(fr);
                break;

        }
    }
}

setLanguage(getAppLanguage());


/**
 * Traduction d'un texte en fonction de la langue courante et des paramètres 
 * Le params est un objet contenant les textes à remplacer.
 * 
 * Exemple: 
 * 
 * REMPLACEMENT DE TEXTE SIMPLE
 * text = "Bonjour {name}, bienvenue sur notre site"
 * params = { name: "John" }
 * resultat -> "Bonjour John, bienvenue sur notre site"
 * 
 * PLURALISATION 
 * Le traitement de la pluralisation se fait de la façon suivante:
 *  - La valeur du paramètre doit être un nombre
 *  - Le texte à remplacer doit être de la forme {key|singular|plural}
 *  - Si la valeur du paramètre est > 1, le texte est remplacé par le texte "plural", sinon par le texte "singular"
 * 
 * Exemple:
 * text = "Bonjour {name}, votre derniere connection remonte à {days} jour{days||s}"
 * params = { name: "John", days: 2 }
 * resultat -> "Bonjour John, votre derniere connection remonte à 2 jours"
 * 
 * Exemple 2:
 * text = "{countPersonne|Il a|Ils ont} mangé {countPommes} pomme{countPommes||s}."
 * 
 * params = { countPersonne: 6, countPommes: 3 }
 * resultat -> "Ils ont mangé 3 pommes."
 * 
 * params = { countPersonne: 1, countPommes: 1 }
 * resultat -> "Il a mangé 1 pomme."
 * 
 * @param {*} text - Le texte à traiter.
 * @param {*} params - Un objet contenant les clés à remplacer dans le texte.
 * 
 * @returns - Le résultat du traitement. Le type de la valeur de retour dépend de l'implémentation.
 */
export const i18nParams = (text, p) => {

    return Object.keys(p).reduce((acc, key) => {

        // normal text replacement, find "key" and replace by its "value"
        let newVal = acc.replaceAll(`{${key}}`, p[key]);

        // pluralization
        if (typeof p[key] === 'number') {
            // eslint-disable-next-line no-constant-condition
            while (true) {
                const pos = newVal.indexOf(`{${key}|`)
                const posEnd = newVal.indexOf(`}`, pos + 1) + 1;
                if (pos === -1) {
                    break
                }

                const pluralization = newVal.substring(pos + 1, posEnd - 1);
                const start = newVal.substring(0, pos);
                const end = newVal.substring(posEnd);

                // eslint-disable-next-line
                const [_, singular, plural] = pluralization.split('|');

                newVal = start
                    + (p[key] > 1
                        ? plural
                        : singular)
                    + end;

            }

        }

        return newVal;

    }, text);


}